export const environment = {
  production: true,
  apiUrl: 'https://api.bb.monkeyflux.com/api',
  appUrl: 'https://conecta.uisi.mx/',
  appTitle: 'UISI',
  version: 'VERSION',
  sentryDsn:
    'https://96fb6c05ae5644e8af058bdac328251d@o263356.ingest.sentry.io/5614153',
  amcharts: {
    chartsV4LicenseKey: 'CH377650283',
    chartsV5LicenseKey: 'AM5C377650283',
    mapsV5LicenseKey: 'AM5M377650283',
  },
  styles: {
    // Colors have to be hex
    primary_color: '#000000',
    secondary_color: '#004b84',
    white_logo: './assets/logos/uisi/uisi-logo.png',
    black_logo: './assets/logos/uisi/uisi-logo.png',
    company_name: 'UISI',
    company_url: 'https://www.uisi.mx/',
    favicon: 'https://www.google.com/s2/favicons?domain=uisi.mx',
    login_background_image: './assets/login-images/laptop.jpg',
  },
  googleMapApi: 'AIzaSyALh4aNXBGiIup9eLrMK0NtrqJ5Dm7MCK0',
  noticeableProjectId: '63LWRzGg8ehYSFF4FXBL',
  noticeableWidgetId: '73ghXqHC8KZhgCEfJFmA',
  chatSpellId: '',
  syncfusionLicense:
    'ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZiWX9YdH1VR2VeV0w=',
};
